/* Fonts */
/* SFUI */
@font-face {
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/hinted-SFUIText-Medium.0d86a195.woff2) format('woff2'), url(/static/media/hinted-SFUIText-Medium.821fc0a4.woff) format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: italic;
  font-weight: bold;
  src: url(/static/media/hinted-SFUIText-BoldItalic.5f0b0560.woff2) format('woff2'), url(/static/media/hinted-SFUIText-BoldItalic.9ba75c9f.woff) format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/hinted-SFUIText-Regular.c21d8b0f.woff2) format('woff2'), url(/static/media/hinted-SFUIText-Regular.a3961e32.woff) format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: italic;
  font-weight: normal;
  src: url(/static/media/hinted-SFUIText-RegularItalic.46c690ce.woff2) format('woff2'), url(/static/media/hinted-SFUIText-RegularItalic.6a283166.woff) format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: italic;
  font-weight: 500;
  src: url(/static/media/hinted-SFUIText-MediumItalic.9f437e6b.woff2) format('woff2'), url(/static/media/hinted-SFUIText-MediumItalic.d810b485.woff) format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: normal;
  font-weight: bold;
  src: url(/static/media/hinted-SFUIText-Bold.574e639f.woff2) format('woff2'), url(/static/media/hinted-SFUIText-Bold.6187952e.woff) format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: normal;
  font-weight: 900;
  src: url(/static/media/hinted-SFUIText-Heavy.b65928e3.woff2) format('woff2'), url(/static/media/hinted-SFUIText-Heavy.b3bfba8d.woff) format('woff');
  font-family: 'SF UI Text';
}
/* SFMono */
@font-face {
  src: url(/static/media/SFMonoRegular.ba965fb7.woff) format('woff');
  font-family: 'SF Mono';
}
/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.container {
  padding: 0 30px 30px;
}
.containerAll {
  padding: 30px;
}
.containerTb {
  padding: 30px 0;
}
.containerLr {
  margin: 0 30px;
}
.containerRbl {
  margin: 0 30px 30px;
}
.textCenter {
  text-align: center;
}
.flex {
  display: flex;
}
.well {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.well:not(:last-child) {
  margin-bottom: 10px;
}
.white {
  color: #fff;
}
.error {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.graph {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.graphHeader,
.graph-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.buttonGroup {
  display: flex;
}
.buttonGroup button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.center {
  justify-content: center;
  align-items: center;
}
.bodyMapDot {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.good,
.success {
  color: #26d87c;
}
.warning {
  color: #f8c81c;
}
.bad,
.danger {
  color: #ee1737;
}
.niceSwitch {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.niceSwitch + .niceSwitch {
  margin-top: calc(-1rem + 8px);
}
.switch {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.switch label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.switch label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.switch label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.switch label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.tableSortButton) {
  padding: 0 !important;
}
th:has(.tableSortButton) .tableSortButton {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.tableSortButton) .tableSortButton::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.tableSortButton) .tableSortButton.descending::after {
  content: '\25BC';
}
th:has(.tableSortButton) .tableSortButton.ascending::after {
  content: '\25B2';
}
table.tableSort th:has(button) {
  padding: 0 !important;
}
table.tableSort th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .printHidden {
    display: none !important;
  }
}
.pageHead {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.pageHead::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.noArrows::-webkit-outer-spin-button,
input.noArrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.noArrows[type='number'] {
  -moz-appearance: textfield;
}
div.light {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  vertical-align: baseline;
  margin: 0;
  outline: 0;
  border: 0;
  background: transparent;
  padding: 0;
  font-size: 100%;
}
body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}
nav ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
a {
  vertical-align: baseline;
  margin: 0;
  background: transparent;
  padding: 0;
  font-size: 100%;
}
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  cursor: help;
  border-bottom: 1px dotted;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  display: block;
  margin: 1em 0;
  border: 0;
  border-top: 1px solid #cccccc;
  padding: 0;
  height: 1px;
}
input,
select {
  vertical-align: middle;
}
* {
  -webkit-font-smoothing: antialiased !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  text-rendering: optimizeLegibility !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}
html {
  height: 100%;
}
body {
  position: relative;
  background-color: #222629;
  width: 100vw;
  max-width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  color: #8a96a0;
  font-weight: 100;
  font-size: 14px;
  -webkit-user-select: none;
  user-select: none;
}
@media print {
  body {
    background-color: transparent;
    color: #1a1d1f;
  }
}
body.ReactModal__Body--open {
  overflow: hidden;
}
::selection {
  background: rgba(138, 150, 160, 0.5);
}
::-moz-selection {
  background: rgba(138, 150, 160, 0.5);
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #8a96a0;
}
h1,
.h1,
h2,
h3 {
  color: #fff;
  font-weight: bold;
}
@media print {
  h1,
  .h1,
  h2,
  h3 {
    color: #1a1d1f;
  }
}
h1,
.h1 {
  font-size: 40px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-weight: bold;
  font-size: 18px;
}
form > *:not(:last-child) {
  margin-bottom: 1rem;
}
.w {
  color: #fff;
}
h5,
.h5 {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
}
h6 {
  margin: 30px 0 10px;
  font-weight: 100;
  font-size: 11px;
  text-transform: uppercase;
}
h2 + p,
h3 + p,
h4 + p,
h5 + p {
  margin-top: 15px;
}
ul {
  list-style: none;
}
p {
  line-height: 1.7em;
}
p:not(:last-child) {
  margin-bottom: 15px;
}
a {
  transition: color 0.15s ease;
  color: #fff;
}
a:hover {
  color: #8a96a0;
}
strong {
  color: inherit;
  font-weight: bold;
}
video::-webkit-media-controls {
  display: none !important;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}
code {
  display: block;
  margin: 5px 0;
  border: 2px dashed #f8c81c;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 7px;
  font-family: 'Fira Code', monospace;
}
code.nice {
  border: none;
}
hr {
  margin: 30px 0;
  border: none;
  border-bottom: 1px solid #41494f;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
.hidden {
  position: absolute;
  top: -1px;
  left: -1px;
  border: 0;
  width: 0;
  height: 0;
}
@layer {
  /* unstyled button */
  button.unstyled {
    border: none;
    background-color: transparent;
    color: #8a96a0;
    text-align: left;
  }
  button.unstyled:not(:disabled) {
    cursor: pointer;
  }
}
.nospin input[type='number']::-webkit-outer-spin-button,
.nospin input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.nospin input[type='number'] {
  -webkit-appearance: textfield;
  appearance: textfield;
}
input[type='number'].nospin::-webkit-outer-spin-button,
input[type='number'].nospin::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='number'].nospin {
  -webkit-appearance: textfield;
  appearance: textfield;
}
.noscroll {
  overflow: hidden;
}
.opacity-0 {
  opacity: 0;
}
.ReactModal__Overlay {
  -webkit-perspective: 1600px;
  perspective: 1600px;
  opacity: 0;
  z-index: 25;
  transition: opacity 150ms ease-in-out;
}
.ReactModal__Overlay > div {
  transition: -webkit-transform 0.15s ease;
  transition: transform 0.15s ease;
  transition: transform 0.15s ease, -webkit-transform 0.15s ease;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
  /* Had to disable this so dnd will play nice inside modals :(
		 * https://github.com/atlassian/react-beautiful-dnd#warning-position-fixed
		 */
  /*> div {
			transform: scale(1);
		}*/
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Overlay--before-close > div {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
.recharts-text {
  fill: #8a96a0;
}
.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}
.recharts-tooltip-cursor,
.recharts-cartesian-axis-line,
.recharts-cartesian-axis-tick-line {
  stroke: #41494f;
}
.recharts-cartesian-grid line {
  stroke: #41494f;
}
.recharts-cartesian-axis > .recharts-text {
  fill: #8a96a0;
  text-anchor: middle;
}
.recharts-polar-angle-axis .recharts-text {
  fill: #8a96a0;
}
.rsel__control {
  transition: border-color 0.15s ease, opacity 0.15s ease !important;
  border-color: #41494f !important;
  border-radius: 8px !important;
  background-color: transparent !important;
  min-height: 39px !important;
}
.rsel__control input {
  color: #8a96a0 !important;
}
.rsel__control:hover {
  border-color: #8a96a0 !important;
}
.rsel__control + div {
  position: relative;
  z-index: 2;
}
.rsel__control--is-disabled {
  opacity: 0.5;
}
.rsel__control--is-focused,
.rsel__control--is-focused:hover {
  box-shadow: none !important;
  border-color: #fff !important;
}
.rsel__placeholder {
  color: rgba(138, 150, 160, 0.3) !important;
}
.rsel__indicator:hover {
  color: #8a96a0 !important;
}
.rsel__indicator-separator {
  background-color: #41494f !important;
}
.rsel__single-value {
  padding: 1px 0;
  color: #fff !important;
}
.rsel__menu {
  position: absolute !important;
  z-index: 25;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #41494f !important;
  background-color: #1a1d1f !important;
}
.rsel__option {
  transition: background-color 0.15s ease;
}
.rsel__option:hover,
.rsel__option--is-focused {
  background-color: rgba(65, 73, 79, 0.25) !important;
}
.rsel__option--is-selected,
.rsel__option--is-selected:hover {
  background-color: #41494f !important;
  color: #fff !important;
}
.rsel__option--is-selected *,
.rsel__option--is-selected:hover * {
  color: #fff !important;
}
.rsel__multi-value__remove:hover {
  background-color: #ee1737 !important;
}
.rsel__multi-value__remove:hover path {
  fill: #fff;
}
.rsel__multi-value {
  padding: 4px;
  font-size: 16px;
}
.rsel__value-container--is-multi {
  padding: 2px !important;
}
.rsel__value-container--is-multi input {
  margin-left: 8px;
}
.rsel__value-container--is-multi .rsel__placeholder {
  margin-left: 8px !important;
}
.rbc-calendar {
  height: 100%;
}
.rbc-calendar .rbc-time-header {
  z-index: 3;
  background-color: #222629;
}
.rbc-calendar .rbc-time-header .rbc-time-header-gutter {
  border-bottom: 1px solid #41494f;
}
.rbc-calendar .rbc-time-gutter.rbc-time-column {
  z-index: 3;
}
.rbc-calendar .rbc-time-gutter.rbc-time-column .rbc-timeslot-wrapper .menu,
.rbc-calendar .rbc-time-gutter.rbc-time-column .rbc-timeslot-wrapper .rbc-timeslot-create {
  display: none;
}
.rbc-calendar .rbc-allday-cell {
  display: none;
}
.rbc-calendar .rbc-current-time-indicator {
  background-color: #f8c81c;
  width: 700%;
}
.rbc-calendar .rbc-timeslot-group {
  min-height: 77px;
}
.rbc-calendar .rbc-timeslot-group .rbc-label {
  display: block;
  background-color: #222629;
  padding: 0 7.5px;
  font-size: 11px;
}
.rbc-calendar .rbc-timeslot-group:not(:first-child) .rbc-label {
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
}
.rbc-calendar .rbc-time-header-content {
  border-left: 0;
}
.rbc-calendar .rbc-header,
.rbc-calendar .rbc-header + .rbc-header {
  border-color: #41494f;
}
.rbc-calendar .rbc-header:first-child {
  border-left: 1px solid transparent;
}
.rbc-calendar .rbc-header + .rbc-header {
  border-image: linear-gradient(to top, #41494f, #222629) 1 1%;
}
.rbc-calendar .rbc-header {
  padding: 1rem;
  text-align: left;
}
.rbc-calendar .rbc-header button {
  letter-spacing: 0.46px;
  text-transform: uppercase;
}
.rbc-calendar .rbc-header button strong {
  display: block;
  margin-top: 0.2rem;
  color: white;
  font-size: 1.5rem;
  text-align: left;
}
.rbc-calendar .rbc-time-header.rbc-overflowing {
  border-right: 0;
}
.rbc-calendar .rbc-day-slot.rbc-time-column:nth-child(2) .rbc-events-container,
.rbc-calendar .rbc-day-slot.rbc-time-column:nth-child(2) .rbc-timeslot-group {
  border-left: 0;
}
.rbc-calendar .rbc-day-slot .rbc-events-container {
  margin-right: 0;
}
.rbc-calendar .rbc-time-slot {
  background-color: #1a1d1f !important;
}
.rbc-calendar .rbc-label.rbc-time-header-gutter {
  background-color: #222629 !important;
}
.rbc-calendar .rbc-label.rbc-time-header-gutter {
  border-right: none;
  border-bottom: 1px #41494f solid;
}
.rbc-calendar .rbc-time-gutter.rbc-time-column {
  border: none;
}
.rbc-calendar .rbc-time-header-content > .rbc-row.rbc-row-resource {
  border: none;
}
.rbc-calendar .rbc-day-slot .rbc-time-slot {
  border-top: 0;
}
.rbc-calendar .rbc-time-view {
  border: none;
  height: 100%;
}
.rbc-calendar .rbc-time-view-resources {
  border: none;
  height: 100%;
}
.rbc-calendar .rbc-time-content {
  border-top: 0;
  overflow-x: hidden;
}
.rbc-calendar .rbc-timeslot-group {
  position: relative;
  border-bottom: 1px solid rgba(65, 73, 79, 0.3);
}
.rbc-calendar .rbc-time-content > * + * > * {
  border-left: 1px solid #41494f;
}
.rbc-calendar .rbc-today {
  background-color: rgba(248, 200, 28, 0.05);
}
.rbc-calendar .rbc-today.rbc-header * {
  color: #f8c81c;
}
.rbc-calendar .rbc-day-slot .rbc-event {
  z-index: 2;
  border: 0;
  border-left: 4px solid;
  overflow: visible;
}
.rbc-calendar .rbc-event-wrapper .rbc-event,
.rbc-calendar .rbc-event.rbc-addons-dnd-drag-preview {
  gap: 0.2rem;
  padding: 0.3rem 0.5rem;
  overflow: hidden;
}
.rbc-calendar .rbc-event-wrapper .rbc-event strong,
.rbc-calendar .rbc-event.rbc-addons-dnd-drag-preview strong {
  display: block;
  margin-bottom: 0.2rem;
}
.rbc-calendar .rbc-event-wrapper .rbc-event *,
.rbc-calendar .rbc-event.rbc-addons-dnd-drag-preview * {
  font-size: 12px;
}
.rbc-calendar .rbc-event-wrapper .rbc-event-label {
  margin-bottom: 0.2rem;
}
.rbc-calendar .rbc-event-wrapper .rbc-event-content svg {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
}
.rbc-calendar .rbc-event-wrapper .rbc-event-content svg path {
  fill: currentColor;
}
.rbc-calendar.rbc-addons-dnd-is-dragging .rbc-event-wrapper.dragging .rbc-event {
  opacity: 0 !important;
}
.rbc-calendar .rbc-event-wrapper.workout .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.workout .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #f8c81c;
  background-color: rgba(248, 200, 28, 0.3);
  color: #f8c81c;
}
.rbc-calendar .rbc-event-wrapper.competition .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.competition .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #48c118;
  background-color: rgba(72, 193, 24, 0.3);
  color: #48c118;
}
.rbc-calendar .rbc-event-wrapper.training .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.training .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #2196f3;
  background-color: rgba(33, 150, 243, 0.3);
  color: #2196f3;
}
.rbc-calendar .rbc-event-wrapper.meal .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.meal .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #ff7f2f;
  background-color: rgba(255, 127, 47, 0.3);
  color: #ff7f2f;
}
.rbc-calendar .rbc-event-wrapper.meeting .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.meeting .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #f48ab9;
  background-color: rgba(244, 138, 185, 0.3);
  color: #f48ab9;
}
.rbc-calendar .rbc-event-wrapper.meeting2 .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.meeting2 .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #ab8af4;
  background-color: rgba(171, 138, 244, 0.3);
  color: #ab8af4;
}
.rbc-calendar .rbc-event-wrapper.travel .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.travel .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #8a96a0;
  background-color: rgba(138, 150, 160, 0.3);
  color: #8a96a0;
}
.ag-theme-alpine {
  --ag-background-color: #1a1d1f;
  --ag-foreground-color: #8a96a0;
  /* Darker text */
  --ag-header-background-color: #1a1d1f;
  /* Custom header background */
  --ag-row-hover-color: #41494f;
  /* Custom row hover effect */
  --ag-font-size: 15px;
  /* Change font size */
  --ag-row-height: 48px;
  /* Increase row height */
  --ag-border-color: #41494f;
  --ag-secondary-border-color: #41494f;
  --ag-input-focus-border-color: #41494f;
  --ag-header-column-resize-handle-color: #f8c81c;
  --ag-alpine-active-color: #f8c81c;
  --ag-range-selection-border-color: #f8c81c;
  --ag-input-focus-border-color: #f8c81c;
  --ag-selected-row-background-color: #222629;
  --ag-value-change-value-highlight-background-color: #41494f;
  --ag-control-panel-background-color: #1a1d1f;
  --ag-advanced-filter-join-pill-color: #1a1d1f;
}
.ag-theme-alpine .ag-header {
  border-bottom: 2px solid #41494f;
  /* Set border color and thickness for header */
}
.ag-theme-alpine .ag-ltr .ag-filter-toolpanel-header {
  background-color: #1a1d1f;
}
.ag-theme-alpine .ag-row:nth-child(odd) {
  border-bottom: 1px solid #41494f;
  /* Set row border color */
  background-color: #1a1d1f;
  color: #fff;
}
.ag-theme-alpine .ag-row:nth-child(odd):hover {
  background-color: #222629;
}
.ag-theme-alpine .ag-row:nth-child(even) {
  border-bottom: 1px solid #41494f;
  /* Set row border color */
  background-color: #1a1d1f;
  color: #fff;
}
.ag-theme-alpine .ag-row:nth-child(even):hover {
  background-color: #222629;
}
.ag-theme-alpine .ag-header {
  font-weight: 300;
  font-size: 0.8rem;
  text-transform: capitalize;
}
.ag-theme-alpine input[class^='ag-'][type='text']:focus {
  box-shadow: 0 0 2px 0.1rem rgba(170, 170, 170, 0.4) !important;
}
.ag-rich-select-value {
  background-color: #1a1d1f;
  color: #ffffff;
}
.ag-virtual-list-item,
.ag-rich-select-virtual-list-item {
  background-color: #1a1d1f !important;
  /* Set background color to black */
  color: white !important;
  /* Optional: Set text color to white for better contrast */
}
.ag-theme-alpine .ag-paging-panel .ag-paging-button {
  color: #f8c81c !important;
}
.ag-theme-alpine .ag-input-field-input ag-text-field-input {
  box-shadow: 0 0 2px 0.1rem #f8c81c;
}
.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
  color: #f8c81c !important;
}
.ag-theme-alpine .ag-column-select-header {
  border-bottom: #222629;
}
.ag-theme-alpine .ag-popup {
  background-color: #1a1d1f;
  /* Change background color */
}
.ag-theme-alpine .ag-popup .ag-menu-header {
  background-color: #1a1d1f;
  /* Change background color */
}
.ag-theme-alpine .ag-popup .ag-menu {
  background-color: #1a1d1f;
  /* Change background color */
}
.ag-theme-alpine .ag-popup .ag-tab {
  background-color: #1a1d1f;
  /* Change background color */
}
.ag-theme-alpine .ag-popup .ag-tab:hover {
  color: #f8c81c;
}
.ag-theme-alpine .ag-popup .ag-tab-selected {
  border-bottom-color: #f8c81c;
  color: #f8c81c;
}
.podium-cell {
  position: relative;
  background: linear-gradient(to right, rgba(0, 255, 0, 0.2) 0%, transparent 50%);
  overflow: hidden;
  /* Ensure the pseudo-element doesn't overflow */
  color: #48c118;
}
.podium-cell::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  /* Place it behind the content */
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: xor;
  background: linear-gradient(to right, #48c118 0%, rgba(0, 255, 0, 0.149) 70%, transparent 100%);
  padding: 1px;
  /* Match the border width */
  content: '';
}
.top-8-cell {
  background: linear-gradient(to right, rgba(255, 255, 0, 0.2) 0%, transparent 50%);
  color: #f8c81c;
}
.top-8-cell::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  /* Place it behind the content */
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: xor;
  background: linear-gradient(to right, #f8c81c, rgba(0, 255, 0, 0.149) 70%, transparent 100%);
  padding: 1px;
  /* Match the border width */
  content: '';
}
.top-16-cell {
  background: linear-gradient(to right, rgba(255, 102, 0, 0.2) 0%, transparent 50%);
  color: #f86a1c;
}
.top-16-cell::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  /* Place it behind the content */
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: xor;
  background: linear-gradient(to right, #f86a1c 0%, rgba(0, 255, 0, 0.149) 70%, transparent 100%);
  padding: 1px;
  /* Match the border width */
  content: '';
}
@media print {
  .rsel__indicator-separator,
  .rsel__dropdown-indicator {
    display: none !important;
  }
}
.rsel__dropdown-indicator {
  display: none !important;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.App_container__1CwJX {
  padding: 0 30px 30px;
}
.App_containerAll__1UlhG {
  padding: 30px;
}
.App_containerTb__2UZuI {
  padding: 30px 0;
}
.App_containerLr__3V9wJ {
  margin: 0 30px;
}
.App_containerRbl__1sVJi {
  margin: 0 30px 30px;
}
.App_textCenter__1F8UM {
  text-align: center;
}
.App_flex__1tx-6 {
  display: flex;
}
.App_well__3O8Ak {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.App_well__3O8Ak:not(:last-child) {
  margin-bottom: 10px;
}
.App_white__3kiof {
  color: #fff;
}
.App_error__2N13P {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.App_disabled__2QL6c {
  opacity: 0.5;
  pointer-events: none;
}
.App_graph__34Y-r {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.App_graphHeader__23up9,
.App_graph-header__272ns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.App_buttonGroup__1X5KZ {
  display: flex;
}
.App_buttonGroup__1X5KZ button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.App_center__1hQMD {
  justify-content: center;
  align-items: center;
}
.App_bodyMapDot__3ak6s {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.App_good__2r5nF,
.App_success__1R4iM {
  color: #26d87c;
}
.App_warning__qGPl2 {
  color: #f8c81c;
}
.App_bad__Gyw0e,
.App_danger__3rMYi {
  color: #ee1737;
}
.App_niceSwitch__Fja_o {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.App_niceSwitch__Fja_o + .App_niceSwitch__Fja_o {
  margin-top: calc(-1rem + 8px);
}
.App_switch__3gyio {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.App_switch__3gyio label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.App_switch__3gyio label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.App_switch__3gyio label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.App_switch__3gyio label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.App_tableSortButton__33Tfy) {
  padding: 0 !important;
}
th:has(.App_tableSortButton__33Tfy) .App_tableSortButton__33Tfy {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.App_tableSortButton__33Tfy) .App_tableSortButton__33Tfy::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.App_tableSortButton__33Tfy) .App_tableSortButton__33Tfy.App_descending__Se5SA::after {
  content: '\25BC';
}
th:has(.App_tableSortButton__33Tfy) .App_tableSortButton__33Tfy.App_ascending__1NRS9::after {
  content: '\25B2';
}
table.App_tableSort__CxqsG th:has(button) {
  padding: 0 !important;
}
table.App_tableSort__CxqsG th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .App_printHidden__1JXsM {
    display: none !important;
  }
}
.App_pageHead__2o-pE {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.App_pageHead__2o-pE::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.App_noArrows__3FhYX::-webkit-outer-spin-button,
input.App_noArrows__3FhYX::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.App_noArrows__3FhYX[type='number'] {
  -moz-appearance: textfield;
}
div.App_light__3eF6y {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes App_spin__3X26n {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes App_spinCentre__I95iU {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
body.fixedHeight,
body.fixedHeight #root,
body.fixedHeight #root main {
  height: 100%;
  overflow: hidden;
  /* safari x scroll */
}
body.smallSidebar .App_sidebar__36K0a {
  -webkit-transform: translateX(calc(-100% +  80px));
  transform: translateX(calc(-100% +  80px));
}
body.smallSidebar .App_nav__189bV {
  -webkit-transform: translateX(calc(100% -  80px - 0.5rem));
  transform: translateX(calc(100% -  80px - 0.5rem));
}
body.smallSidebar .App_nav__189bV a span {
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}
body.smallSidebar .App_version__3ltWY {
  display: none;
}
body.smallSidebar .App_logout__2Fzwu {
  -webkit-transform: translateX(calc(100% - 80px - 30px / 2));
  transform: translateX(calc(100% - 80px - 30px / 2));
}
body.smallSidebar .App_logout__2Fzwu span {
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
}
body.smallSidebar .App_logout__2Fzwu svg {
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
}
body.smallSidebar .App_body__3Dahg {
  margin-left: 80px;
}
.App_sidebar__36K0a {
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 20;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  background-color: #1a1d1f;
  width: 240px;
  height: 100vh;
  overflow: auto;
}
@media (max-width: 1367px) {
  .App_sidebar__36K0a {
    -webkit-transform: translateX(calc(-100% +  80px));
    transform: translateX(calc(-100% +  80px));
  }
}
@media print {
  .App_sidebar__36K0a {
    display: none;
    transition: none;
  }
}
.App_sidebar__36K0a.App_super__1P-Wm {
  border-right-color: #8a96a0;
  background-color: #41494f;
}
.App_logo__2PrSP {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 30px;
  height: calc(100px + 30px*2);
}
.App_logo__2PrSP img {
  max-width: 80px;
  height: auto;
}
.App_nav__189bV {
  flex-grow: 1;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  padding: 0 1rem 1rem;
}
@media (max-width: 1367px) {
  .App_nav__189bV {
    -webkit-transform: translateX(calc(100% -  80px - 0.5rem));
    transform: translateX(calc(100% -  80px - 0.5rem));
  }
}
.App_nav__189bV a {
  display: flex;
  align-items: center;
  transition: color 0.15s ease, background-color 0.15s ease;
  margin: 0 0 10px;
  border-radius: 20px;
  padding: 1.1rem;
  width: 100%;
  height: 32px;
  color: #8a96a0;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
}
.App_nav__189bV a svg {
  vertical-align: middle;
  margin-right: 15px;
  width: 20px;
}
.App_nav__189bV a svg line,
.App_nav__189bV a svg path,
.App_nav__189bV a svg polyline,
.App_nav__189bV a svg polygon,
.App_nav__189bV a svg rect {
  stroke: #8a96a0;
  transition: stroke 0.15s ease;
}
.App_nav__189bV a span {
  flex-grow: 1;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  color: inherit;
}
@media (max-width: 1367px) {
  .App_nav__189bV a span {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
}
.App_nav__189bV a i {
  display: inline-block;
  border-radius: 50%;
  background-color: #f8c81c;
  width: 8px;
  height: 8px;
}
.App_nav__189bV a.App_active__1oTbg,
.App_nav__189bV a:hover {
  color: #fff;
}
.App_nav__189bV a.App_active__1oTbg svg line,
.App_nav__189bV a:hover svg line,
.App_nav__189bV a.App_active__1oTbg svg path,
.App_nav__189bV a:hover svg path,
.App_nav__189bV a.App_active__1oTbg svg polyline,
.App_nav__189bV a:hover svg polyline,
.App_nav__189bV a.App_active__1oTbg svg polygon,
.App_nav__189bV a:hover svg polygon,
.App_nav__189bV a.App_active__1oTbg svg rect,
.App_nav__189bV a:hover svg rect {
  stroke: #fff;
}
.App_nav__189bV a.App_active__1oTbg {
  background-color: #485056;
}
.smallSidebar .App_collapseMenuButton__336RX {
  align-self: flex-end;
  width: 50px;
}
.smallSidebar .App_collapseMenuButton__336RX svg {
  rotate: 180deg;
}
.App_collapseMenuButton__336RX {
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 0.5rem;
  transition: color 0.15s, width 0.5s;
  cursor: pointer;
  margin: 1rem;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.5rem 1rem;
  width: calc(100% - 2rem);
  font-size: 0.875rem;
  text-transform: uppercase;
  white-space: nowrap;
}
@media (max-width: 1367px) {
  .App_collapseMenuButton__336RX {
    display: none;
  }
}
.App_collapseMenuButton__336RX .App_text__3WtYb {
  overflow: hidden;
}
.App_collapseMenuButton__336RX svg {
  min-width: 18px;
}
.App_collapseMenuButton__336RX svg g {
  transition: stroke 0.15s;
}
.App_collapseMenuButton__336RX:hover {
  color: #a6afb7;
}
.App_collapseMenuButton__336RX:hover svg g {
  stroke: #a6afb7;
}
.App_version__3ltWY {
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  padding: 15px 30px;
  color: rgba(138, 150, 160, 0.5);
  font-size: 11px;
}
.App_version__3ltWY button {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  margin: 0 0 0 10px;
  border: none;
  border-radius: 0;
  background: none;
  padding: 0;
  color: red;
}
@media (max-width: 1367px) {
  .App_version__3ltWY {
    display: none;
  }
}
.App_logout__2Fzwu {
  -webkit-appearance: none;
  appearance: none;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  cursor: pointer;
  border: none;
  border-top: 1px solid #41494f;
  background: none;
  padding: 30px;
  color: #8a96a0;
  font-weight: bold;
  font-size: 15px;
  text-align: left;
  text-decoration: none;
}
@media (max-width: 1367px) {
  .App_logout__2Fzwu {
    -webkit-transform: translateX(calc(100% - 80px - 30px / 2));
    transform: translateX(calc(100% - 80px - 30px / 2));
  }
}
.App_super__1P-Wm .App_logout__2Fzwu {
  border-top-color: #485056;
}
.App_logout__2Fzwu span {
  display: inline-block;
  transition: color 0.15s ease, -webkit-transform 0.5s ease;
  transition: color 0.15s ease, transform 0.5s ease;
  transition: color 0.15s ease, transform 0.5s ease, -webkit-transform 0.5s ease;
}
@media (max-width: 1367px) {
  .App_logout__2Fzwu span {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}
.App_logout__2Fzwu svg {
  vertical-align: middle;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  margin-right: 15px;
  width: 20px;
}
.App_logout__2Fzwu svg line,
.App_logout__2Fzwu svg path,
.App_logout__2Fzwu svg polyline,
.App_logout__2Fzwu svg polygon,
.App_logout__2Fzwu svg rect {
  stroke: #8a96a0;
  transition: stroke 0.15s ease;
}
@media (max-width: 1367px) {
  .App_logout__2Fzwu svg {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
  }
}
.App_logout__2Fzwu.App_active__1oTbg span,
.App_logout__2Fzwu:hover span {
  color: #fff;
}
.App_logout__2Fzwu.App_active__1oTbg svg line,
.App_logout__2Fzwu:hover svg line,
.App_logout__2Fzwu.App_active__1oTbg svg path,
.App_logout__2Fzwu:hover svg path,
.App_logout__2Fzwu.App_active__1oTbg svg polyline,
.App_logout__2Fzwu:hover svg polyline,
.App_logout__2Fzwu.App_active__1oTbg svg polygon,
.App_logout__2Fzwu:hover svg polygon,
.App_logout__2Fzwu.App_active__1oTbg svg rect,
.App_logout__2Fzwu:hover svg rect {
  stroke: #fff;
}
.App_body__3Dahg {
  display: flex;
  flex-direction: column;
  transition: margin-left 0.5s ease;
  margin-left: 240px;
  min-height: 100vh;
}
@media print {
  .App_body__3Dahg {
    transition: none;
    margin-left: 0 !important;
  }
}
@media (max-width: 1367px) {
  .App_body__3Dahg {
    margin-left: 80px;
  }
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Notifications_container__3NzNt {
  padding: 0 30px 30px;
}
.Notifications_containerAll__26PHs {
  padding: 30px;
}
.Notifications_containerTb__2WnRj {
  padding: 30px 0;
}
.Notifications_containerLr__5z0yP {
  margin: 0 30px;
}
.Notifications_containerRbl__25tQI {
  margin: 0 30px 30px;
}
.Notifications_textCenter__3Ojk6 {
  text-align: center;
}
.Notifications_flex__E9I9N {
  display: flex;
}
.Notifications_well__1hEhi {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.Notifications_well__1hEhi:not(:last-child) {
  margin-bottom: 10px;
}
.Notifications_white__2oksQ {
  color: #fff;
}
.Notifications_error__3ydon {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.Notifications_disabled__20Ieb {
  opacity: 0.5;
  pointer-events: none;
}
.Notifications_graph__txhlo {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.Notifications_graphHeader__EExI0,
.Notifications_graph-header__1rUki {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.Notifications_buttonGroup__1N93f {
  display: flex;
}
.Notifications_buttonGroup__1N93f button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Notifications_center__2-YvH {
  justify-content: center;
  align-items: center;
}
.Notifications_bodyMapDot__ymOFV {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.Notifications_good__3Wn-1,
.Notifications_success__3d985 {
  color: #26d87c;
}
.Notifications_warning__Rv--z {
  color: #f8c81c;
}
.Notifications_bad__3eVn3,
.Notifications_danger__35_-0 {
  color: #ee1737;
}
.Notifications_niceSwitch__2LIVS {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.Notifications_niceSwitch__2LIVS + .Notifications_niceSwitch__2LIVS {
  margin-top: calc(-1rem + 8px);
}
.Notifications_switch__J7O15 {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.Notifications_switch__J7O15 label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.Notifications_switch__J7O15 label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.Notifications_switch__J7O15 label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.Notifications_switch__J7O15 label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.Notifications_tableSortButton__3Sjrd) {
  padding: 0 !important;
}
th:has(.Notifications_tableSortButton__3Sjrd) .Notifications_tableSortButton__3Sjrd {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.Notifications_tableSortButton__3Sjrd) .Notifications_tableSortButton__3Sjrd::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.Notifications_tableSortButton__3Sjrd) .Notifications_tableSortButton__3Sjrd.Notifications_descending__10Ybk::after {
  content: '\25BC';
}
th:has(.Notifications_tableSortButton__3Sjrd) .Notifications_tableSortButton__3Sjrd.Notifications_ascending__3ulbB::after {
  content: '\25B2';
}
table.Notifications_tableSort__3CLMY th:has(button) {
  padding: 0 !important;
}
table.Notifications_tableSort__3CLMY th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .Notifications_printHidden__21RKz {
    display: none !important;
  }
}
.Notifications_pageHead__3ct1e {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.Notifications_pageHead__3ct1e::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.Notifications_noArrows__bERhX::-webkit-outer-spin-button,
input.Notifications_noArrows__bERhX::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.Notifications_noArrows__bERhX[type='number'] {
  -moz-appearance: textfield;
}
div.Notifications_light__oj0pY {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes Notifications_spin__1huO_ {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Notifications_spinCentre__1t6yJ {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Notifications_list__33Mat {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
  width: 100%;
  max-width: 380px;
  pointer-events: none;
  list-style: none;
}
.Notifications_li__2UtBt {
  display: block;
  padding: 10px 10px 0;
  width: 100%;
  overflow: visible;
}
.Notifications_notification__1zCbF {
  display: flex;
  align-items: center;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  pointer-events: all;
  color: #8a96a0;
  font-size: 12px;
  line-height: 1.5em;
  text-decoration: none;
}
.Notifications_notification__1zCbF > div {
  flex-shrink: 9999;
  padding-left: 20px;
}
.Notifications_notification__1zCbF strong {
  display: block;
  margin-bottom: 2px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Auth_container__1RPCR {
  padding: 0 30px 30px;
}
.Auth_containerAll__3aqPB {
  padding: 30px;
}
.Auth_containerTb__vMKOx {
  padding: 30px 0;
}
.Auth_containerLr__2imh2 {
  margin: 0 30px;
}
.Auth_containerRbl__3VMKe {
  margin: 0 30px 30px;
}
.Auth_textCenter__1dgKL {
  text-align: center;
}
.Auth_flex__JKult {
  display: flex;
}
.Auth_well__AgeR6 {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.Auth_well__AgeR6:not(:last-child) {
  margin-bottom: 10px;
}
.Auth_white__2CpeX {
  color: #fff;
}
.Auth_error__2n2YI {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.Auth_disabled__1XYgP {
  opacity: 0.5;
  pointer-events: none;
}
.Auth_graph__3QfD5 {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.Auth_graphHeader__2UeNy,
.Auth_graph-header__1MiGR {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.Auth_buttonGroup__10aHT {
  display: flex;
}
.Auth_buttonGroup__10aHT button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Auth_center__3XY_s {
  justify-content: center;
  align-items: center;
}
.Auth_bodyMapDot__Pi3l8 {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.Auth_good__2XxA-,
.Auth_success__2y7TD {
  color: #26d87c;
}
.Auth_warning__2ddnL {
  color: #f8c81c;
}
.Auth_bad__3fGwT,
.Auth_danger__26PTW {
  color: #ee1737;
}
.Auth_niceSwitch__2C3fM {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.Auth_niceSwitch__2C3fM + .Auth_niceSwitch__2C3fM {
  margin-top: calc(-1rem + 8px);
}
.Auth_switch__2UJ36 {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.Auth_switch__2UJ36 label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.Auth_switch__2UJ36 label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.Auth_switch__2UJ36 label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.Auth_switch__2UJ36 label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.Auth_tableSortButton__1c8Dw) {
  padding: 0 !important;
}
th:has(.Auth_tableSortButton__1c8Dw) .Auth_tableSortButton__1c8Dw {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.Auth_tableSortButton__1c8Dw) .Auth_tableSortButton__1c8Dw::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.Auth_tableSortButton__1c8Dw) .Auth_tableSortButton__1c8Dw.Auth_descending__3nK3S::after {
  content: '\25BC';
}
th:has(.Auth_tableSortButton__1c8Dw) .Auth_tableSortButton__1c8Dw.Auth_ascending__3gNkV::after {
  content: '\25B2';
}
table.Auth_tableSort__205N8 th:has(button) {
  padding: 0 !important;
}
table.Auth_tableSort__205N8 th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .Auth_printHidden__1Wqn6 {
    display: none !important;
  }
}
.Auth_pageHead__2zDFR {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.Auth_pageHead__2zDFR::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.Auth_noArrows__3kDHR::-webkit-outer-spin-button,
input.Auth_noArrows__3kDHR::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.Auth_noArrows__3kDHR[type='number'] {
  -moz-appearance: textfield;
}
div.Auth_light__3KIdJ {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes Auth_spin__nGsUB {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Auth_spinCentre__2DY_n {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Auth_wrap__L5hlN {
  display: flex;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
@media (max-width: 1000px) {
  .Auth_wrap__L5hlN {
    flex-direction: column-reverse;
    height: auto;
    min-height: 100vh;
    font-size: 16px;
  }
  .Auth_wrap__L5hlN h3 {
    font-size: 30px;
    line-height: 1.25em;
  }
}
.Auth_form__M2lv- {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  -webkit-transform: translateX(-50vw);
  transform: translateX(-50vw);
  z-index: 2;
  transition: -webkit-transform 0.75s ease;
  transition: transform 0.75s ease;
  transition: transform 0.75s ease, -webkit-transform 0.75s ease;
  background-color: #222629;
  padding: 30px;
  width: 50vw;
  height: 100vh;
}
@media (max-width: 1000px) {
  .Auth_form__M2lv- {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    width: 100vw;
    height: auto;
    min-height: calc(100vh - 200px);
  }
}
.Auth_validSlug__3cVqq .Auth_form__M2lv- {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.Auth_form__M2lv- h3 {
  margin-bottom: 30px;
  width: 100%;
}
.Auth_form__M2lv- h3 + p {
  margin-top: -15px;
  margin-bottom: 30px;
}
.Auth_form__M2lv- form {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  width: 100%;
}
@media (max-width: 1000px) {
  .Auth_form__M2lv- form {
    align-items: flex-start;
    margin-top: -67.5px;
  }
}
.Auth_form__M2lv- footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  font-size: 12px;
}
.Auth_form__M2lv- footer .Auth_branding__1lz0W {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  font-weight: lighter;
  font-size: 16px;
}
.Auth_form__M2lv- footer .Auth_logo__3JZrX svg {
  width: 24px;
  /* Scale down the SVG width */
  height: 24px;
  /* Scale down the SVG height */
}
.Auth_form__M2lv- footer span {
  display: inline-block;
  padding: 0 10px;
}
.Auth_form__M2lv- footer a {
  color: #8a96a0;
  text-decoration: none;
}
.Auth_form__M2lv- footer a:hover {
  text-decoration: underline;
}
.Auth_form-wrap__3l_I6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}
.Auth_form-wrap__3l_I6 img {
  object-fit: contain;
}
.Auth_form-wrap__3l_I6 > *:not(:last-child, svg) {
  margin-bottom: 1.875rem;
}
.Auth_form-wrap__3l_I6 > div {
  width: 100%;
}
.Auth_form-wrap__3l_I6 > svg {
  margin-bottom: 60px;
}
.Auth_form-wrap__3l_I6 label {
  width: 100%;
}
.Auth_form-wrap__3l_I6 button {
  margin: 0 0 30px;
  width: 100%;
}
.Auth_notice__2tTXR {
  margin: 0 0 30px;
  width: 100%;
}
.Auth_img__2lN5t {
  position: relative;
  z-index: 1;
  width: 50vw;
  height: 100vh;
}
@media (max-width: 1000px) {
  .Auth_img__2lN5t {
    width: 100vw;
    height: 200px;
  }
}
.Auth_img__2lN5t img {
  -webkit-transform: translateX(-50vw);
  transform: translateX(-50vw);
  transition: -webkit-transform 0.75s ease !important;
  transition: transform 0.75s ease !important;
  transition: transform 0.75s ease, -webkit-transform 0.75s ease !important;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}
.Auth_validSlug__3cVqq .Auth_img__2lN5t img {
  -webkit-transform: translateX(-25vw);
  transform: translateX(-25vw);
}
@media (max-width: 1000px) {
  .Auth_img__2lN5t img {
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
  }
}
.Auth_fade__hCe-j {
  opacity: 0.3 !important;
}
s .Auth_input__3kuHS {
  margin-bottom: 15px;
  border: 1px solid #41494f;
  border-radius: 4px;
  padding: 0 0 0 14px;
  width: 100%;
}
.Auth_why__H-ALf {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Auth_why__H-ALf button {
  opacity: 0.25;
  margin: 0;
  width: auto;
}
.Auth_why__H-ALf button span {
  text-decoration: none;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.NewsFeed_container__3TMEw {
  padding: 0 30px 30px;
}
.NewsFeed_containerAll__1UHw4 {
  padding: 30px;
}
.NewsFeed_containerTb__1rejL {
  padding: 30px 0;
}
.NewsFeed_containerLr__Q0Hv- {
  margin: 0 30px;
}
.NewsFeed_containerRbl__1LGNJ {
  margin: 0 30px 30px;
}
.NewsFeed_textCenter__2DWD- {
  text-align: center;
}
.NewsFeed_flex__3pRHB {
  display: flex;
}
.NewsFeed_well__11eI6 {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.NewsFeed_well__11eI6:not(:last-child) {
  margin-bottom: 10px;
}
.NewsFeed_white__1Dn-e {
  color: #fff;
}
.NewsFeed_error__34rw3 {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.NewsFeed_disabled__2Ic3- {
  opacity: 0.5;
  pointer-events: none;
}
.NewsFeed_graph__2w3vt {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.NewsFeed_graphHeader__35KK2,
.NewsFeed_graph-header__27mXH {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.NewsFeed_buttonGroup__1D503 {
  display: flex;
}
.NewsFeed_buttonGroup__1D503 button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.NewsFeed_center__3F7Ge {
  justify-content: center;
  align-items: center;
}
.NewsFeed_bodyMapDot__1gbkh {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.NewsFeed_good__2GtG2,
.NewsFeed_success__3DKe1 {
  color: #26d87c;
}
.NewsFeed_warning__3lpJ6 {
  color: #f8c81c;
}
.NewsFeed_bad__6Cy5t,
.NewsFeed_danger__2rY6w {
  color: #ee1737;
}
.NewsFeed_niceSwitch__1FtGo {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.NewsFeed_niceSwitch__1FtGo + .NewsFeed_niceSwitch__1FtGo {
  margin-top: calc(-1rem + 8px);
}
.NewsFeed_switch__Q4hhN {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.NewsFeed_switch__Q4hhN label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.NewsFeed_switch__Q4hhN label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.NewsFeed_switch__Q4hhN label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.NewsFeed_switch__Q4hhN label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.NewsFeed_tableSortButton__1EZqZ) {
  padding: 0 !important;
}
th:has(.NewsFeed_tableSortButton__1EZqZ) .NewsFeed_tableSortButton__1EZqZ {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.NewsFeed_tableSortButton__1EZqZ) .NewsFeed_tableSortButton__1EZqZ::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.NewsFeed_tableSortButton__1EZqZ) .NewsFeed_tableSortButton__1EZqZ.NewsFeed_descending__2tdgw::after {
  content: '\25BC';
}
th:has(.NewsFeed_tableSortButton__1EZqZ) .NewsFeed_tableSortButton__1EZqZ.NewsFeed_ascending__1DByK::after {
  content: '\25B2';
}
table.NewsFeed_tableSort__1EwUy th:has(button) {
  padding: 0 !important;
}
table.NewsFeed_tableSort__1EwUy th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .NewsFeed_printHidden__1Hzkh {
    display: none !important;
  }
}
.NewsFeed_pageHead__Cl-Dv {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.NewsFeed_pageHead__Cl-Dv::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.NewsFeed_noArrows__3jDKj::-webkit-outer-spin-button,
input.NewsFeed_noArrows__3jDKj::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.NewsFeed_noArrows__3jDKj[type='number'] {
  -moz-appearance: textfield;
}
div.NewsFeed_light__1Q1RA {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes NewsFeed_spin__2EKgr {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes NewsFeed_spinCentre__yuFLz {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.smallSidebar .NewsFeed_feed-btn__2yPMw {
  -webkit-transform: translate3d(65px, -50%, 0);
  transform: translate3d(65px, -50%, 0);
}
.NewsFeed_feed-btn__2yPMw {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(68px, -50%, 0);
  transform: translate3d(68px, -50%, 0);
  -webkit-appearance: none;
  appearance: none;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background: none;
}
.NewsFeed_feed-btn__2yPMw svg {
  pointer-events: none;
}
@media (max-width: 1367px) {
  .NewsFeed_feed-btn__2yPMw {
    -webkit-transform: translate3d(65px, -50%, 0);
    transform: translate3d(65px, -50%, 0);
  }
}
.NewsFeed_overlay__S-zWC {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 240px;
  opacity: 0;
  z-index: 25;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: opacity 0.2s ease;
  background-color: rgba(34, 38, 41, 0.8);
  overflow: hidden;
}
@media (max-width: 1367px) {
  .NewsFeed_overlay__S-zWC {
    left: 80px;
  }
}
.NewsFeed_overlayOpen__1BXVu {
  opacity: 1;
}
.NewsFeed_overlayClose__37qj6 {
  opacity: 0;
  transition-delay: 0.1s;
}
.NewsFeed_modal__34I_8 {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 1;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  inset: unset;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  border: none;
  border-right: 1px solid #41494f;
  border-radius: 0;
  background-color: #222629;
  padding: 30px;
  width: 100%;
  max-width: 650px;
  height: 100vh;
  overflow: auto;
}
.NewsFeed_modal__34I_8 h3 {
  margin-bottom: 30px;
}
.NewsFeed_modalOpen__1Mkx9 {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.NewsFeed_modalClose__32vXX {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.NewsFeedItem_container__3GYu4 {
  padding: 0 30px 30px;
}
.NewsFeedItem_containerAll__33_tu {
  padding: 30px;
}
.NewsFeedItem_containerTb__1F_Ze {
  padding: 30px 0;
}
.NewsFeedItem_containerLr__2XQeb {
  margin: 0 30px;
}
.NewsFeedItem_containerRbl__3zrMc {
  margin: 0 30px 30px;
}
.NewsFeedItem_textCenter__yuG4k {
  text-align: center;
}
.NewsFeedItem_flex__3yhgA {
  display: flex;
}
.NewsFeedItem_well__2T1hE {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.NewsFeedItem_well__2T1hE:not(:last-child) {
  margin-bottom: 10px;
}
.NewsFeedItem_white__S3E4K {
  color: #fff;
}
.NewsFeedItem_error__2MidZ {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.NewsFeedItem_disabled__3h3iv {
  opacity: 0.5;
  pointer-events: none;
}
.NewsFeedItem_graph__3khCF {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.NewsFeedItem_graphHeader__1WauO,
.NewsFeedItem_graph-header__g1SWN {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.NewsFeedItem_buttonGroup__1eRet {
  display: flex;
}
.NewsFeedItem_buttonGroup__1eRet button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.NewsFeedItem_center__2QSep {
  justify-content: center;
  align-items: center;
}
.NewsFeedItem_bodyMapDot__Civ36 {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.NewsFeedItem_good__14Kmf,
.NewsFeedItem_success__19RAG {
  color: #26d87c;
}
.NewsFeedItem_warning__19Oaf {
  color: #f8c81c;
}
.NewsFeedItem_bad__3Z-Qt,
.NewsFeedItem_danger__1AmGg {
  color: #ee1737;
}
.NewsFeedItem_niceSwitch__m31Na {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.NewsFeedItem_niceSwitch__m31Na + .NewsFeedItem_niceSwitch__m31Na {
  margin-top: calc(-1rem + 8px);
}
.NewsFeedItem_switch__O_cJI {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.NewsFeedItem_switch__O_cJI label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.NewsFeedItem_switch__O_cJI label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.NewsFeedItem_switch__O_cJI label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.NewsFeedItem_switch__O_cJI label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.NewsFeedItem_tableSortButton__31ZZs) {
  padding: 0 !important;
}
th:has(.NewsFeedItem_tableSortButton__31ZZs) .NewsFeedItem_tableSortButton__31ZZs {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.NewsFeedItem_tableSortButton__31ZZs) .NewsFeedItem_tableSortButton__31ZZs::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.NewsFeedItem_tableSortButton__31ZZs) .NewsFeedItem_tableSortButton__31ZZs.NewsFeedItem_descending__35o6-::after {
  content: '\25BC';
}
th:has(.NewsFeedItem_tableSortButton__31ZZs) .NewsFeedItem_tableSortButton__31ZZs.NewsFeedItem_ascending__akhs9::after {
  content: '\25B2';
}
table.NewsFeedItem_tableSort__3ZDFC th:has(button) {
  padding: 0 !important;
}
table.NewsFeedItem_tableSort__3ZDFC th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .NewsFeedItem_printHidden__3vaqp {
    display: none !important;
  }
}
.NewsFeedItem_pageHead__9-viq {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.NewsFeedItem_pageHead__9-viq::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.NewsFeedItem_noArrows__3F-Od::-webkit-outer-spin-button,
input.NewsFeedItem_noArrows__3F-Od::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.NewsFeedItem_noArrows__3F-Od[type='number'] {
  -moz-appearance: textfield;
}
div.NewsFeedItem_light__17s8A {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes NewsFeedItem_spin__VpLN7 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes NewsFeedItem_spinCentre__WYbFU {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.NewsFeedItem_item__2WV7P {
  display: flex;
  align-items: center;
  transition: border-color 10s ease;
  margin-bottom: 10px;
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
  padding: 10px;
  font-size: 16px;
}
.NewsFeedItem_item__2WV7P:last-child {
  margin-bottom: 30px;
}
.NewsFeedItem_item__2WV7P.NewsFeedItem_unread__2_F5T {
  border-color: #f8c81c;
}
.NewsFeedItem_item__2WV7P img {
  vertical-align: middle;
  margin-right: 15px;
  border-radius: 2px;
  width: 40px;
  height: 40px;
}
.NewsFeedItem_item__2WV7P a {
  font-weight: bold;
  text-decoration: none;
}
.NewsFeedItem_item__2WV7P em {
  color: #fff;
  font-style: normal;
}
.NewsFeedItem_item__2WV7P p {
  flex-grow: 1;
  margin: 0 0 3px !important;
  line-height: 1.2em;
}
.NewsFeedItem_item__2WV7P time {
  color: #8a96a0;
  font-weight: bold;
  font-size: 11px;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Avatar_container__3uHcM {
  padding: 0 30px 30px;
}
.Avatar_containerAll__2MoxL {
  padding: 30px;
}
.Avatar_containerTb__7Codt {
  padding: 30px 0;
}
.Avatar_containerLr__1KC4T {
  margin: 0 30px;
}
.Avatar_containerRbl__3VMYp {
  margin: 0 30px 30px;
}
.Avatar_textCenter__1oAPs {
  text-align: center;
}
.Avatar_flex__qdtti {
  display: flex;
}
.Avatar_well__3L6_Y {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.Avatar_well__3L6_Y:not(:last-child) {
  margin-bottom: 10px;
}
.Avatar_white__1KX07 {
  color: #fff;
}
.Avatar_error__1gM3X {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.Avatar_disabled__1xaxr {
  opacity: 0.5;
  pointer-events: none;
}
.Avatar_graph__tHdr6 {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.Avatar_graphHeader__35b2H,
.Avatar_graph-header__2yOoY {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.Avatar_buttonGroup__2TNt8 {
  display: flex;
}
.Avatar_buttonGroup__2TNt8 button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Avatar_center__3Joa7 {
  justify-content: center;
  align-items: center;
}
.Avatar_bodyMapDot__1afJn {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.Avatar_good__2KPdw,
.Avatar_success__jjERI {
  color: #26d87c;
}
.Avatar_warning__2DmAx {
  color: #f8c81c;
}
.Avatar_bad__281id,
.Avatar_danger__2XNT7 {
  color: #ee1737;
}
.Avatar_niceSwitch__2iPe7 {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.Avatar_niceSwitch__2iPe7 + .Avatar_niceSwitch__2iPe7 {
  margin-top: calc(-1rem + 8px);
}
.Avatar_switch__Dj1tO {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.Avatar_switch__Dj1tO label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.Avatar_switch__Dj1tO label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.Avatar_switch__Dj1tO label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.Avatar_switch__Dj1tO label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.Avatar_tableSortButton__3lrNl) {
  padding: 0 !important;
}
th:has(.Avatar_tableSortButton__3lrNl) .Avatar_tableSortButton__3lrNl {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.Avatar_tableSortButton__3lrNl) .Avatar_tableSortButton__3lrNl::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.Avatar_tableSortButton__3lrNl) .Avatar_tableSortButton__3lrNl.Avatar_descending__1K9ED::after {
  content: '\25BC';
}
th:has(.Avatar_tableSortButton__3lrNl) .Avatar_tableSortButton__3lrNl.Avatar_ascending__1V0sa::after {
  content: '\25B2';
}
table.Avatar_tableSort__3SC9r th:has(button) {
  padding: 0 !important;
}
table.Avatar_tableSort__3SC9r th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .Avatar_printHidden__1B8UO {
    display: none !important;
  }
}
.Avatar_pageHead__GiK7P {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.Avatar_pageHead__GiK7P::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.Avatar_noArrows__25haZ::-webkit-outer-spin-button,
input.Avatar_noArrows__25haZ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.Avatar_noArrows__25haZ[type='number'] {
  -moz-appearance: textfield;
}
div.Avatar_light__2kmm5 {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes Avatar_spin__17fzM {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Avatar_spinCentre__1tydG {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Avatar_root__2_mBR.Avatar_small__QOdqi {
  width: 36px;
  height: 36px;
}
.Avatar_root__2_mBR.Avatar_round__2dC_e {
  border-radius: 50%;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Spinner_container__Skeo- {
  padding: 0 30px 30px;
}
.Spinner_containerAll__3mLmM {
  padding: 30px;
}
.Spinner_containerTb__YT5Hv {
  padding: 30px 0;
}
.Spinner_containerLr__21M66 {
  margin: 0 30px;
}
.Spinner_containerRbl__3M7Xw {
  margin: 0 30px 30px;
}
.Spinner_textCenter__xNH14 {
  text-align: center;
}
.Spinner_flex__2HhMr {
  display: flex;
}
.Spinner_well__XmtCc {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.Spinner_well__XmtCc:not(:last-child) {
  margin-bottom: 10px;
}
.Spinner_white__2fuhu {
  color: #fff;
}
.Spinner_error__33QBW {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.Spinner_disabled__1S3-x {
  opacity: 0.5;
  pointer-events: none;
}
.Spinner_graph__60tZC {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.Spinner_graphHeader__1Ojk0,
.Spinner_graph-header__2Eisl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.Spinner_buttonGroup__22zjO {
  display: flex;
}
.Spinner_buttonGroup__22zjO button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Spinner_center__hPMUb {
  justify-content: center;
  align-items: center;
}
.Spinner_bodyMapDot__11BH5 {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.Spinner_good__1dLj-,
.Spinner_success__2Fxod {
  color: #26d87c;
}
.Spinner_warning__FUXI0 {
  color: #f8c81c;
}
.Spinner_bad__3ytlx,
.Spinner_danger__2DtJj {
  color: #ee1737;
}
.Spinner_niceSwitch__2Ypxt {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.Spinner_niceSwitch__2Ypxt + .Spinner_niceSwitch__2Ypxt {
  margin-top: calc(-1rem + 8px);
}
.Spinner_switch__1XrU3 {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.Spinner_switch__1XrU3 label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.Spinner_switch__1XrU3 label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.Spinner_switch__1XrU3 label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.Spinner_switch__1XrU3 label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.Spinner_tableSortButton__BizAN) {
  padding: 0 !important;
}
th:has(.Spinner_tableSortButton__BizAN) .Spinner_tableSortButton__BizAN {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.Spinner_tableSortButton__BizAN) .Spinner_tableSortButton__BizAN::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.Spinner_tableSortButton__BizAN) .Spinner_tableSortButton__BizAN.Spinner_descending__3bwEX::after {
  content: '\25BC';
}
th:has(.Spinner_tableSortButton__BizAN) .Spinner_tableSortButton__BizAN.Spinner_ascending__zDrSp::after {
  content: '\25B2';
}
table.Spinner_tableSort__3DlYQ th:has(button) {
  padding: 0 !important;
}
table.Spinner_tableSort__3DlYQ th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .Spinner_printHidden__3Crjn {
    display: none !important;
  }
}
.Spinner_pageHead__N9Ef0 {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.Spinner_pageHead__N9Ef0::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.Spinner_noArrows__30kdQ::-webkit-outer-spin-button,
input.Spinner_noArrows__30kdQ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.Spinner_noArrows__30kdQ[type='number'] {
  -moz-appearance: textfield;
}
div.Spinner_light__12WgX {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes Spinner_spin__MvZTe {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Spinner_spinCentre__3_VsT {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Spinner_spinner__3LAOo {
  display: inline-block;
  opacity: 0;
  -webkit-animation: Spinner_spin__MvZTe 1s linear infinite;
  animation: Spinner_spin__MvZTe 1s linear infinite;
  transition: opacity 0.15s ease;
  border: 3px solid #8a96a0;
  border-top-color: transparent;
  border-radius: 50%;
  pointer-events: none;
}
.Spinner_centre__3Y4wd {
  -webkit-animation: Spinner_spinCentre__3_VsT 1s linear infinite;
  animation: Spinner_spinCentre__3_VsT 1s linear infinite;
}
.Spinner_show__8QIs0 {
  opacity: 1;
  transition-delay: 0.75s;
}
.Spinner_small__1Z1yu {
  width: 22.5px;
  height: 22.5px;
}
.Spinner_medium__2eiqZ {
  width: 45px;
  height: 45px;
}
.Spinner_large__3Auht {
  width: 60px;
  height: 60px;
}
.Spinner_block__2KB2S {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;
}
.Spinner_noStretch__3s0xV {
  margin-top: 30px;
  height: auto;
}
.Spinner_fixed__1Hu18 {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.ErrorList_container__2vi8b {
  padding: 0 30px 30px;
}
.ErrorList_containerAll__32yhJ {
  padding: 30px;
}
.ErrorList_containerTb__2ILle {
  padding: 30px 0;
}
.ErrorList_containerLr__19fKZ {
  margin: 0 30px;
}
.ErrorList_containerRbl__3WaZz {
  margin: 0 30px 30px;
}
.ErrorList_textCenter__1geA2 {
  text-align: center;
}
.ErrorList_flex__cFHNh {
  display: flex;
}
.ErrorList_well__3sXTy {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.ErrorList_well__3sXTy:not(:last-child) {
  margin-bottom: 10px;
}
.ErrorList_white__24JzJ {
  color: #fff;
}
.ErrorList_error__39NgL {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.ErrorList_disabled__2py0N {
  opacity: 0.5;
  pointer-events: none;
}
.ErrorList_graph__3tq1l {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.ErrorList_graphHeader__2iW3N,
.ErrorList_graph-header__vRNGe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.ErrorList_buttonGroup__3GW7U {
  display: flex;
}
.ErrorList_buttonGroup__3GW7U button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.ErrorList_center__2If0- {
  justify-content: center;
  align-items: center;
}
.ErrorList_bodyMapDot__2cvd7 {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.ErrorList_good__2qTqE,
.ErrorList_success__3BhgB {
  color: #26d87c;
}
.ErrorList_warning__ptQRO {
  color: #f8c81c;
}
.ErrorList_bad__3COuU,
.ErrorList_danger__1GBkA {
  color: #ee1737;
}
.ErrorList_niceSwitch__1L7jz {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.ErrorList_niceSwitch__1L7jz + .ErrorList_niceSwitch__1L7jz {
  margin-top: calc(-1rem + 8px);
}
.ErrorList_switch__20V00 {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.ErrorList_switch__20V00 label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.ErrorList_switch__20V00 label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.ErrorList_switch__20V00 label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.ErrorList_switch__20V00 label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.ErrorList_tableSortButton__6Mj4p) {
  padding: 0 !important;
}
th:has(.ErrorList_tableSortButton__6Mj4p) .ErrorList_tableSortButton__6Mj4p {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.ErrorList_tableSortButton__6Mj4p) .ErrorList_tableSortButton__6Mj4p::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.ErrorList_tableSortButton__6Mj4p) .ErrorList_tableSortButton__6Mj4p.ErrorList_descending__mphOy::after {
  content: '\25BC';
}
th:has(.ErrorList_tableSortButton__6Mj4p) .ErrorList_tableSortButton__6Mj4p.ErrorList_ascending__1ZGPh::after {
  content: '\25B2';
}
table.ErrorList_tableSort__3KrZl th:has(button) {
  padding: 0 !important;
}
table.ErrorList_tableSort__3KrZl th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .ErrorList_printHidden__2yEz8 {
    display: none !important;
  }
}
.ErrorList_pageHead__2X5IK {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.ErrorList_pageHead__2X5IK::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.ErrorList_noArrows__3W1Qq::-webkit-outer-spin-button,
input.ErrorList_noArrows__3W1Qq::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.ErrorList_noArrows__3W1Qq[type='number'] {
  -moz-appearance: textfield;
}
div.ErrorList_light__149fJ {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes ErrorList_spin__2Vlbu {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes ErrorList_spinCentre__14V3k {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.ErrorList_errorList__3_dHC {
  display: block;
  border: 1px solid #c40e29;
  border-radius: 5px;
  background-color: rgba(238, 23, 55, 0.05);
  padding: 15px;
  color: #ee1737;
  line-height: 1.5em;
  list-style: none;
}
.ErrorList_errorList__3_dHC:not(:last-child) {
  margin-bottom: 30px;
}
.ErrorList_errorList__3_dHC li:not(:last-child) {
  margin-bottom: 10px;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Divider_container__2IMSC {
  padding: 0 30px 30px;
}
.Divider_containerAll__1q36t {
  padding: 30px;
}
.Divider_containerTb__2EI95 {
  padding: 30px 0;
}
.Divider_containerLr__30Qio {
  margin: 0 30px;
}
.Divider_containerRbl__aHKKe {
  margin: 0 30px 30px;
}
.Divider_textCenter__26GSj {
  text-align: center;
}
.Divider_flex__3X8rH {
  display: flex;
}
.Divider_well__3XRpc {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.Divider_well__3XRpc:not(:last-child) {
  margin-bottom: 10px;
}
.Divider_white__1LJUc {
  color: #fff;
}
.Divider_error__VONfM {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.Divider_disabled__O-5Ov {
  opacity: 0.5;
  pointer-events: none;
}
.Divider_graph__3N6I6 {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.Divider_graphHeader__Al7n_,
.Divider_graph-header__PR_3H {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.Divider_buttonGroup__21-Ir {
  display: flex;
}
.Divider_buttonGroup__21-Ir button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Divider_center__2rOu9 {
  justify-content: center;
  align-items: center;
}
.Divider_bodyMapDot__1NKvX {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.Divider_good__2AIRh,
.Divider_success__1MJgZ {
  color: #26d87c;
}
.Divider_warning__2YeAd {
  color: #f8c81c;
}
.Divider_bad__2hrZK,
.Divider_danger__3WcS9 {
  color: #ee1737;
}
.Divider_niceSwitch__xAjsT {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.Divider_niceSwitch__xAjsT + .Divider_niceSwitch__xAjsT {
  margin-top: calc(-1rem + 8px);
}
.Divider_switch__H3rP- {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.Divider_switch__H3rP- label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.Divider_switch__H3rP- label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.Divider_switch__H3rP- label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.Divider_switch__H3rP- label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.Divider_tableSortButton__1lEHA) {
  padding: 0 !important;
}
th:has(.Divider_tableSortButton__1lEHA) .Divider_tableSortButton__1lEHA {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.Divider_tableSortButton__1lEHA) .Divider_tableSortButton__1lEHA::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.Divider_tableSortButton__1lEHA) .Divider_tableSortButton__1lEHA.Divider_descending__3dZRk::after {
  content: '\25BC';
}
th:has(.Divider_tableSortButton__1lEHA) .Divider_tableSortButton__1lEHA.Divider_ascending__1zCbc::after {
  content: '\25B2';
}
table.Divider_tableSort__2qcmO th:has(button) {
  padding: 0 !important;
}
table.Divider_tableSort__2qcmO th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .Divider_printHidden__3_PB8 {
    display: none !important;
  }
}
.Divider_pageHead__38h03 {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.Divider_pageHead__38h03::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.Divider_noArrows__14dDG::-webkit-outer-spin-button,
input.Divider_noArrows__14dDG::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.Divider_noArrows__14dDG[type='number'] {
  -moz-appearance: textfield;
}
div.Divider_light__3HOKx {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes Divider_spin__tmjDM {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Divider_spinCentre__1Wmwn {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Divider_divider__2RIrX {
  display: flex;
  align-items: center;
  margin: 30px 0;
  color: #8a96a0;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0;
  text-transform: uppercase;
}
.Divider_divider__2RIrX:after {
  display: block;
  flex-grow: 1;
  margin-left: 10px;
  background-color: #41494f;
  height: 1px;
  content: '';
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.styles_container__13Vg8 {
  padding: 0 30px 30px;
}
.styles_containerAll__2Y76w {
  padding: 30px;
}
.styles_containerTb__xVaCu {
  padding: 30px 0;
}
.styles_containerLr__-eqL2 {
  margin: 0 30px;
}
.styles_containerRbl__OEHpy {
  margin: 0 30px 30px;
}
.styles_textCenter__nOctP {
  text-align: center;
}
.styles_flex__3OPpO {
  display: flex;
}
.styles_well__P-iAu {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.styles_well__P-iAu:not(:last-child) {
  margin-bottom: 10px;
}
.styles_white__1Zok6 {
  color: #fff;
}
.styles_error__1hfPS {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.styles_disabled__3KAzZ {
  opacity: 0.5;
  pointer-events: none;
}
.styles_graph__2ruWY {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.styles_graphHeader__BB1PS,
.styles_graph-header__2Civ2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.styles_buttonGroup__29XVL {
  display: flex;
}
.styles_buttonGroup__29XVL button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.styles_center__2f5YH {
  justify-content: center;
  align-items: center;
}
.styles_bodyMapDot__2pjFS {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.styles_good__2Hxmp,
.styles_success__3zaaj {
  color: #26d87c;
}
.styles_warning__1PWRt {
  color: #f8c81c;
}
.styles_bad__2nFNW,
.styles_danger__3X80C {
  color: #ee1737;
}
.styles_niceSwitch__25zU0 {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.styles_niceSwitch__25zU0 + .styles_niceSwitch__25zU0 {
  margin-top: calc(-1rem + 8px);
}
.styles_switch__2h6Nz {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.styles_switch__2h6Nz label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.styles_switch__2h6Nz label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.styles_switch__2h6Nz label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.styles_switch__2h6Nz label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.styles_tableSortButton__BO7iN) {
  padding: 0 !important;
}
th:has(.styles_tableSortButton__BO7iN) .styles_tableSortButton__BO7iN {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.styles_tableSortButton__BO7iN) .styles_tableSortButton__BO7iN::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.styles_tableSortButton__BO7iN) .styles_tableSortButton__BO7iN.styles_descending__Q_l7f::after {
  content: '\25BC';
}
th:has(.styles_tableSortButton__BO7iN) .styles_tableSortButton__BO7iN.styles_ascending__i7NrE::after {
  content: '\25B2';
}
table.styles_tableSort__1lAsM th:has(button) {
  padding: 0 !important;
}
table.styles_tableSort__1lAsM th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .styles_printHidden__3ARWE {
    display: none !important;
  }
}
.styles_pageHead__31AUQ {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.styles_pageHead__31AUQ::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.styles_noArrows__YTSCH::-webkit-outer-spin-button,
input.styles_noArrows__YTSCH::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.styles_noArrows__YTSCH[type='number'] {
  -moz-appearance: textfield;
}
div.styles_light__1wMjP {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes styles_spin__EAprR {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes styles_spinCentre__127_Q {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.styles_switchBtn__3rTx- {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-100px, -50%, 0);
  transform: translate3d(-100px, -50%, 0);
  -webkit-appearance: none;
  appearance: none;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background: none;
}
.styles_switchBtn__3rTx- svg {
  pointer-events: none;
}
@media (max-width: 1367px) {
  .styles_switchBtn__3rTx- {
    -webkit-transform: translate3d(95px, -175%, 0);
    transform: translate3d(95px, -175%, 0);
  }
}
.styles_overlay__2yTp1 {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: flex-start;
  opacity: 0;
  z-index: 25;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: opacity 0.3s ease;
  background-color: rgba(26, 29, 31, 0.8);
  overflow: hidden;
}
.styles_overlayOpen__3gVNR {
  opacity: 1;
}
.styles_overlayClose__2RQC0 {
  opacity: 0;
  transition-delay: 0.1s;
}
.styles_modal__1SrOH {
  opacity: 1;
  margin: 0 auto;
  inset: unset;
  border: none;
  background: none;
  padding: 100px 30px;
  width: 100%;
  max-width: 960px;
  max-height: 100vh;
  overflow: auto;
}
.styles_modal__1SrOH h3 {
  margin-bottom: 30px;
}
.styles_header__1SuOu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}
.styles_header__1SuOu input {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.5);
  padding: 11px 0;
  min-width: 50%;
  color: #fff;
  text-indent: 16px;
}
.styles_header__1SuOu input::-webkit-input-placeholder {
  color: rgba(138, 150, 160, 0.5);
}
.styles_header__1SuOu input::placeholder {
  color: rgba(138, 150, 160, 0.5);
}
.styles_tabs__3gYpx {
  display: flex;
  position: relative;
  justify-content: stretch;
  align-items: center;
  border-radius: 8px;
  background: #1a1d1f;
  padding: 3px;
}
.styles_tabs__3gYpx::before {
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 0;
  transition: -webkit-transform 0.15s ease;
  transition: transform 0.15s ease;
  transition: transform 0.15s ease, -webkit-transform 0.15s ease;
  border-radius: 4px;
  background: #8a96a0;
  width: 115px;
  height: calc(100% - 6px);
  content: '';
}
.styles_tabs__3gYpx.styles_flip__2E6BQ::before {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.styles_tabs__3gYpx.styles_flip__2E6BQ button:first-child {
  color: #8a96a0;
}
.styles_tabs__3gYpx.styles_flip__2E6BQ button:last-child {
  color: #1a1d1f;
}
.styles_tabs__3gYpx button {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  z-index: 2;
  transition: color 0.15s ease;
  cursor: pointer;
  border: none;
  background: none;
  padding: 10px 0;
  width: 115px;
  color: #8a96a0;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}
.styles_tabs__3gYpx button:first-child {
  color: #1a1d1f;
}
.styles_orgs__2BIOb {
  grid-gap: 50px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  list-style: none;
}
.styles_orgs__2BIOb li button,
.styles_orgs__2BIOb li a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  border: none;
  border-radius: 0;
  background: none;
  width: 100%;
  color: #8a96a0;
  font-size: 13px;
  text-align: center;
  text-decoration: none;
}
.styles_orgs__2BIOb li button span,
.styles_orgs__2BIOb li a span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 20px;
  background: #fff;
  width: 100px;
  height: 100px;
  overflow: hidden;
  color: #f8c81c;
  font-weight: bold;
  font-size: 54px;
}
.styles_orgs__2BIOb li button img,
.styles_orgs__2BIOb li a img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.styles_orgs__2BIOb li button svg,
.styles_orgs__2BIOb li a svg {
  vertical-align: middle;
}
.styles_orgs__2BIOb li button small,
.styles_orgs__2BIOb li a small {
  display: block;
  opacity: 0.5;
  margin-top: 5px;
  font-size: 11px;
}
.styles_current__6oIZi {
  opacity: 0.5;
  pointer-events: none;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.NoAccess_container__1gnfC {
  padding: 0 30px 30px;
}
.NoAccess_containerAll__3kVkQ {
  padding: 30px;
}
.NoAccess_containerTb__3_f0V {
  padding: 30px 0;
}
.NoAccess_containerLr__2aUKv {
  margin: 0 30px;
}
.NoAccess_containerRbl__1_ULZ {
  margin: 0 30px 30px;
}
.NoAccess_textCenter__3S8UD {
  text-align: center;
}
.NoAccess_flex__1iA7- {
  display: flex;
}
.NoAccess_well__2LiUU {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.NoAccess_well__2LiUU:not(:last-child) {
  margin-bottom: 10px;
}
.NoAccess_white__IWjx2 {
  color: #fff;
}
.NoAccess_error__1pRGQ {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.NoAccess_disabled__tVYl2 {
  opacity: 0.5;
  pointer-events: none;
}
.NoAccess_graph__LT-t9 {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.NoAccess_graphHeader__3j4lL,
.NoAccess_graph-header__2kCxh {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.NoAccess_buttonGroup__3du3m {
  display: flex;
}
.NoAccess_buttonGroup__3du3m button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.NoAccess_center__3rFgl {
  justify-content: center;
  align-items: center;
}
.NoAccess_bodyMapDot__1IihA {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.NoAccess_good__2tY3-,
.NoAccess_success__2lPGN {
  color: #26d87c;
}
.NoAccess_warning__5zQO3 {
  color: #f8c81c;
}
.NoAccess_bad__1P-Jt,
.NoAccess_danger__10l8F {
  color: #ee1737;
}
.NoAccess_niceSwitch__3ZQ0h {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.NoAccess_niceSwitch__3ZQ0h + .NoAccess_niceSwitch__3ZQ0h {
  margin-top: calc(-1rem + 8px);
}
.NoAccess_switch__1PEld {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.NoAccess_switch__1PEld label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.NoAccess_switch__1PEld label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.NoAccess_switch__1PEld label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.NoAccess_switch__1PEld label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.NoAccess_tableSortButton__QoIlK) {
  padding: 0 !important;
}
th:has(.NoAccess_tableSortButton__QoIlK) .NoAccess_tableSortButton__QoIlK {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.NoAccess_tableSortButton__QoIlK) .NoAccess_tableSortButton__QoIlK::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.NoAccess_tableSortButton__QoIlK) .NoAccess_tableSortButton__QoIlK.NoAccess_descending__19Boz::after {
  content: '\25BC';
}
th:has(.NoAccess_tableSortButton__QoIlK) .NoAccess_tableSortButton__QoIlK.NoAccess_ascending__30va0::after {
  content: '\25B2';
}
table.NoAccess_tableSort__23hnx th:has(button) {
  padding: 0 !important;
}
table.NoAccess_tableSort__23hnx th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .NoAccess_printHidden__1Ftgi {
    display: none !important;
  }
}
.NoAccess_pageHead__2rs3g {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.NoAccess_pageHead__2rs3g::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.NoAccess_noArrows__3FVdN::-webkit-outer-spin-button,
input.NoAccess_noArrows__3FVdN::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.NoAccess_noArrows__3FVdN[type='number'] {
  -moz-appearance: textfield;
}
div.NoAccess_light__2SCVh {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes NoAccess_spin__28OvM {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes NoAccess_spinCentre__1OsYo {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.NoAccess_wrap__G8wAD {
  padding: 30px;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.ErrorScreen_container__btADA {
  padding: 0 30px 30px;
}
.ErrorScreen_containerAll__3PJdc {
  padding: 30px;
}
.ErrorScreen_containerTb__2vnNR {
  padding: 30px 0;
}
.ErrorScreen_containerLr__G0YX7 {
  margin: 0 30px;
}
.ErrorScreen_containerRbl__2I_wM {
  margin: 0 30px 30px;
}
.ErrorScreen_textCenter__lR6oT {
  text-align: center;
}
.ErrorScreen_flex__9KLi4 {
  display: flex;
}
.ErrorScreen_well__1qerM {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.ErrorScreen_well__1qerM:not(:last-child) {
  margin-bottom: 10px;
}
.ErrorScreen_white__u4M2P {
  color: #fff;
}
.ErrorScreen_error__3FHpj {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.ErrorScreen_disabled__3xHhj {
  opacity: 0.5;
  pointer-events: none;
}
.ErrorScreen_graph__2lWeV {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.ErrorScreen_graphHeader__3t4m0,
.ErrorScreen_graph-header__26vVU {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.ErrorScreen_buttonGroup__2bpsU {
  display: flex;
}
.ErrorScreen_buttonGroup__2bpsU button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.ErrorScreen_center__deID4 {
  justify-content: center;
  align-items: center;
}
.ErrorScreen_bodyMapDot__e7VXM {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.ErrorScreen_good__3lrGC,
.ErrorScreen_success__Ybysj {
  color: #26d87c;
}
.ErrorScreen_warning__15oyY {
  color: #f8c81c;
}
.ErrorScreen_bad__2lsS0,
.ErrorScreen_danger__2jJ4g {
  color: #ee1737;
}
.ErrorScreen_niceSwitch__smWhH {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.ErrorScreen_niceSwitch__smWhH + .ErrorScreen_niceSwitch__smWhH {
  margin-top: calc(-1rem + 8px);
}
.ErrorScreen_switch__2k72- {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.ErrorScreen_switch__2k72- label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.ErrorScreen_switch__2k72- label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.ErrorScreen_switch__2k72- label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.ErrorScreen_switch__2k72- label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.ErrorScreen_tableSortButton__2asHW) {
  padding: 0 !important;
}
th:has(.ErrorScreen_tableSortButton__2asHW) .ErrorScreen_tableSortButton__2asHW {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.ErrorScreen_tableSortButton__2asHW) .ErrorScreen_tableSortButton__2asHW::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.ErrorScreen_tableSortButton__2asHW) .ErrorScreen_tableSortButton__2asHW.ErrorScreen_descending__3pEkF::after {
  content: '\25BC';
}
th:has(.ErrorScreen_tableSortButton__2asHW) .ErrorScreen_tableSortButton__2asHW.ErrorScreen_ascending__2hQ6x::after {
  content: '\25B2';
}
table.ErrorScreen_tableSort__3kDLZ th:has(button) {
  padding: 0 !important;
}
table.ErrorScreen_tableSort__3kDLZ th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .ErrorScreen_printHidden__3N9ue {
    display: none !important;
  }
}
.ErrorScreen_pageHead__3zOEA {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.ErrorScreen_pageHead__3zOEA::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.ErrorScreen_noArrows__FywiG::-webkit-outer-spin-button,
input.ErrorScreen_noArrows__FywiG::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.ErrorScreen_noArrows__FywiG[type='number'] {
  -moz-appearance: textfield;
}
div.ErrorScreen_light__2Pw7w {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes ErrorScreen_spin__u2_dz {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes ErrorScreen_spinCentre__2mHlF {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.ErrorScreen_errorScreen__1QdDF {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding: 30px;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
.ErrorScreen_errorScreen__1QdDF:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.1;
  z-index: -1;
  background-image: url(/static/media/quino.b7cb4007.jpg);
  background-size: cover;
  content: '';
}
.ErrorScreen_errorScreen__1QdDF > div {
  margin: auto;
  max-width: 600px;
}
.ErrorScreen_errorScreen__1QdDF h1 {
  margin-bottom: 30px;
}
.ErrorScreen_errorScreen__1QdDF .ErrorScreen_stack__39Sza {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 15px;
  font-size: 11px;
  line-height: 1.5em;
}
.ErrorScreen_errorScreen__1QdDF .ErrorScreen_stack__39Sza strong {
  margin-bottom: 10px;
}
.ErrorScreen_errorScreen__1QdDF .ErrorScreen_stack__39Sza code {
  display: block;
  margin: 0;
  border: none;
  background: none;
  padding: 0;
  overflow: hidden;
  font-family: 'Fira Code', monospace;
  text-overflow: ellipsis;
}
.ErrorScreen_errorScreen__1QdDF.ErrorScreen_overlay__1M4I- {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(26, 29, 31, 0.9);
  width: auto;
  height: auto;
}
.ErrorScreen_errorScreen__1QdDF.ErrorScreen_overlay__1M4I-:before {
  content: none;
}
.ErrorScreen_footer__2EeDq {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 0;
}
.ErrorScreen_footer__2EeDq button {
  margin-bottom: 0 !important;
}
.ErrorScreen_footer__2EeDq button:not(:last-child) {
  margin-right: 10px;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Button_container__1Wdfm {
  padding: 0 30px 30px;
}
.Button_containerAll__2VyHN {
  padding: 30px;
}
.Button_containerTb__J7X_- {
  padding: 30px 0;
}
.Button_containerLr__em6jd {
  margin: 0 30px;
}
.Button_containerRbl__3KpxR {
  margin: 0 30px 30px;
}
.Button_textCenter__3Di2m {
  text-align: center;
}
.Button_flex__1jWND {
  display: flex;
}
.Button_well__3pCpH {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.Button_well__3pCpH:not(:last-child) {
  margin-bottom: 10px;
}
.Button_white__1s6uA {
  color: #fff;
}
.Button_error__1QZge {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.Button_disabled__2mcmd {
  opacity: 0.5;
  pointer-events: none;
}
.Button_graph__foFdS {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.Button_graphHeader__1kNqs,
.Button_graph-header__2uSRt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.Button_buttonGroup__1b_PN {
  display: flex;
}
.Button_buttonGroup__1b_PN button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Button_center__3ms4j {
  justify-content: center;
  align-items: center;
}
.Button_bodyMapDot__3-WoA {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.Button_good__3oDkh,
.Button_success__1VqQT {
  color: #26d87c;
}
.Button_warning__1a8XT {
  color: #f8c81c;
}
.Button_bad__A61Ak,
.Button_danger__2EOxJ {
  color: #ee1737;
}
.Button_niceSwitch__2Ia1u {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.Button_niceSwitch__2Ia1u + .Button_niceSwitch__2Ia1u {
  margin-top: calc(-1rem + 8px);
}
.Button_switch__1WsFV {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.Button_switch__1WsFV label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.Button_switch__1WsFV label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.Button_switch__1WsFV label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.Button_switch__1WsFV label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.Button_tableSortButton__26eFQ) {
  padding: 0 !important;
}
th:has(.Button_tableSortButton__26eFQ) .Button_tableSortButton__26eFQ {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.Button_tableSortButton__26eFQ) .Button_tableSortButton__26eFQ::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.Button_tableSortButton__26eFQ) .Button_tableSortButton__26eFQ.Button_descending__32nD8::after {
  content: '\25BC';
}
th:has(.Button_tableSortButton__26eFQ) .Button_tableSortButton__26eFQ.Button_ascending__3mL-s::after {
  content: '\25B2';
}
table.Button_tableSort__2rUX4 th:has(button) {
  padding: 0 !important;
}
table.Button_tableSort__2rUX4 th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .Button_printHidden__2C2BQ {
    display: none !important;
  }
}
.Button_pageHead__38_-H {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.Button_pageHead__38_-H::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.Button_noArrows__2MJg-::-webkit-outer-spin-button,
input.Button_noArrows__2MJg-::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.Button_noArrows__2MJg-[type='number'] {
  -moz-appearance: textfield;
}
div.Button_light__28-8I {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes Button_spin__2VRsM {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Button_spinCentre__8c_rp {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Button_button__1Xlil {
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  transition: all 0.15s ease;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  text-decoration: none;
}
.Button_button__1Xlil svg:first-child {
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 10px;
}
.Button_button__1Xlil span {
  position: static;
  transition: opacity 0.15s ease;
  color: #222629 !important;
  font-weight: bold;
  font-size: 12px;
  line-height: normal;
  text-decoration: none;
  text-transform: uppercase;
}
.Button_button__1Xlil:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  opacity: 0;
  transition: opacity 0.15s ease;
  border: 3px solid #f8c81c;
  border-top-color: transparent !important;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  content: '';
}
.Button_button__1Xlil:hover {
  opacity: 0.5;
  text-decoration: none;
}
.Button_button__1Xlil:hover span {
  text-decoration: none;
}
.Button_button__1Xlil:disabled {
  opacity: 0.25;
  pointer-events: none;
}
.Button_primary__218gm {
  background-color: #fff;
}
.Button_primary__218gm:after {
  border-color: #222629;
}
.Button_secondary__2leml {
  background-color: #8a96a0;
}
.Button_secondary__2leml:after {
  border-color: #fff;
}
.Button_tertiary__paK0l {
  background-color: #1a1d1f;
}
.Button_tertiary__paK0l span {
  color: #8a96a0 !important;
}
.Button_quaternary__3DgiV {
  background-color: #41494f;
}
.Button_quaternary__3DgiV:hover {
  opacity: 1 !important;
  background-color: #8a96a0;
}
.Button_brand__1SLKj {
  background-color: #f8c81c;
}
.Button_brand__1SLKj:after {
  border-color: #fff;
}
.Button_dashed__1cdoC {
  border: 1px dashed #41494f;
  border-radius: 8px;
  background: none;
  padding: 7.5px 1rem !important;
}
.Button_dashed__1cdoC span {
  color: #8a96a0 !important;
  font-weight: bold;
  font-size: 0.6875rem;
  text-transform: uppercase;
}
.Button_dashed__1cdoC:after {
  border-color: #41494f;
}
.Button_danger__2EOxJ {
  border: none;
  border-radius: 5px;
  background: rgba(238, 23, 55, 0.3);
}
.Button_danger__2EOxJ span {
  color: #ee1737 !important;
}
.Button_danger__2EOxJ:after {
  border-color: #ee1737;
}
.Button_info__k8KH0 {
  border: 1px solid #2196f3;
  border-radius: 5px;
  background: none;
}
.Button_info__k8KH0 span {
  color: #2196f3 !important;
}
.Button_info__k8KH0:after {
  border-color: #2196f3;
}
.Button_success__1VqQT {
  border: 1px solid #26d87c;
  border-radius: 5px;
  background: #26d87c;
}
.Button_success__1VqQT span {
  color: #222629 !important;
}
.Button_success__1VqQT:after {
  border-color: #1a1d1f;
}
.Button_danger-small__111XD {
  border: none;
  background: none;
}
.Button_danger-small__111XD span {
  color: #ee1737 !important;
  font-size: 12px;
  text-transform: none;
}
.Button_danger-small__111XD:after {
  border-color: #ee1737;
}
.Button_none__1M12N {
  border-radius: 0;
  background: none;
}
.Button_text__37rNB {
  border: none;
  border-radius: 0;
  background: none;
  padding: 0 !important;
  color: #fff;
}
.Button_text__37rNB span {
  color: currentColor !important;
  font-weight: normal;
  text-decoration: underline !important;
  text-transform: none;
}
.Button_outline__3na86 {
  border: 1px solid #41494f;
  background: none;
}
.Button_outline__3na86 span {
  color: #8a96a0 !important;
  font-weight: normal;
}
.Button_small__3IXJO {
  padding: 5px 10px 4px;
}
.Button_small__3IXJO:after {
  width: 8px;
  height: 8px;
}
.Button_small__3IXJO.Button_danger__2EOxJ {
  padding: 4px 10px 3px;
}
.Button_small__3IXJO span {
  font-size: 13px;
}
.Button_large__OxrNh {
  padding: 11px 30px;
}
.Button_large__OxrNh.Button_danger__2EOxJ {
  padding: 9px 15px;
}
.Button_huge__LbIBi {
  padding: 20px;
  width: 100%;
}
.Button_huge__LbIBi:after {
  width: 16px;
  height: 16px;
}
.Button_huge__LbIBi.Button_danger__2EOxJ {
  padding: 19px 20px;
}
.Button_round__3ZEOP {
  border-radius: 20px;
  padding: 0.55rem 1.75rem;
}
.Button_wide__qU1bi {
  width: 100%;
}
.Button_busy__1G-Df {
  tab-index: 0;
  pointer-events: none;
}
.Button_busy__1G-Df span {
  opacity: 0;
}
.Button_busy__1G-Df:after {
  opacity: 1;
  -webkit-animation: Button_spinCentre__8c_rp 1s linear infinite;
  animation: Button_spinCentre__8c_rp 1s linear infinite;
}

