/* Colours
// ========================================================================= */

@background-border-darkest: #0d1010;
@background-border-dark: #1a1d1f;
@background-border-medium: #222629;
@background-border-light: #41494f;

@primary: #f8c81c;
@secondary: #8a96a0;

@text-color: @secondary;

/*@success: #48C118;*/
@success: #26d87c;
@info: #2196f3;
@warning: @primary;
@danger: #ee1737;

@high: @danger;
@high-transparent: #ee173780;
@high-transparent2: #ee173712;
@medium: #f86a1c;
@low: #f5a623;
@low-transparent: #f5a52380;
@low-transparent2: #f5a52312;

@chart-1: #2196f3;
@chart-2: #f74d71;
@chart-3: #f48ab9;
@chart-4: #48c118;
@chart-5: #f8c81c;
@chart-6: #7e58c3;
@chart-7: #30e6ee;
@chart-8: #fdefed;

@selection: fade(@secondary, 50%);

@appointment: #f48ab9;
@game: #48c118;
@meal: #ff7f2f;
@meeting2: #ab8af4;
@training: #2196f3;
@travel: #8a96a0;
@workout: #f8c81c;

@vald: #ff7a00;

/* Typography
// ========================================================================= */

@font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';
@font-family-mono: 'SF Mono', SFMono-Regular, ui-monospace, Consolas,
  'Andale Mono WT', 'Andale Mono', 'Lucida Console', 'Lucida Sans Typewriter',
  'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono',
  'Nimbus Mono L', Monaco, 'Courier New', Courier, monospace;

/* z-Index
// ========================================================================= */

@z-notifications: 30;
@z-modal: 25;
@z-sidebar: 20;
@z-header: 5;

/* Sizing
// ========================================================================= */

@spacer: 30px;

@sidebar-width: 240px;
@sidebar-width-collapsed: 80px;

@athlete-header-large: 290px;
@athlete-header-subNav: 30px;
@athlete-header-small: 243px;

@collapseSidebarWidth: 1367px;

/* Reusable Components
// ========================================================================= */

.container {
  padding: 0 @spacer @spacer;
}

.containerAll {
  padding: @spacer;
}

.containerTb {
  padding: @spacer 0;
}

.containerLr {
  margin: 0 @spacer;
}

.containerRbl {
  margin: 0 @spacer @spacer;
}

.textCenter {
  text-align: center;
}

.flex {
  display: flex;
}

.well {
  border: 1px solid @background-border-light;
  border-radius: 5px;
  background-color: @background-border-dark;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.white {
  color: #fff;
}

.error {
  margin-bottom: 0.5rem;
  color: @danger;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.graph {
  border: 1px solid @background-border-light;
  border-radius: 5px;
  padding: @spacer @spacer @spacer / 2 0;
}

.graphHeader,
.graph-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 @spacer @spacer;
}

.buttonGroup {
  display: flex;

  button:not(:last-child) {
    margin: 0 5px 0 0 !important;
  }
}

.center {
  justify-content: center;
  align-items: center;
}

.bodyMapDot {
  position: absolute;
  background: radial-gradient(
    50% 50% at 50% 50%,
    @danger 0%,
    rgba(238, 23, 55, 0.0001) 100%
  );
  width: 20px;
  height: 20px;
}

.good,
.success {
  color: @success;
}

.warning {
  color: @warning;
}

.bad,
.danger {
  color: @danger;
}

.niceSwitch {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: @background-border-dark;
  padding: 10px 12px;

  & + & {
    margin-top: calc(-1rem + 8px);
  }
}

.switch {
  position: relative;
  border-radius: 16px;
  background-color: @background-border-medium;
  padding: 0.2rem;

  label {
    display: inline-block;
    transition: all 0.15s;
    cursor: pointer;
    border-radius: 16px;
    padding: 0.5rem 0.5rem;
    font-weight: bold;
    font-size: 0.75rem;

    &:has(input:checked) {
      background-color: white;
      color: @background-border-dark;
    }

    &:has(input:focus-visible) {
      outline: -webkit-focus-ring-color auto 1px;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
}

th:has(.tableSortButton) {
  padding: 0 !important;

  .tableSortButton {
    cursor: pointer;
    padding: 1rem;
    width: 100%;

    &::after {
      cursor: pointer;
      margin-left: 0.2rem;
    }

    &.descending::after {
      content: '▼';
    }

    &.ascending::after {
      content: '▲';
    }
  }
}

table.tableSort {
  th:has(button) {
    padding: 0 !important;

    > button {
      cursor: pointer;
      padding: 1rem !important;
      width: 100%;
    }
  }
}

.printHidden {
  @media print {
    display: none !important;
  }
}

.pageHead {
  position: relative;
  background-color: @background-border-dark;
  padding: 2rem;

  &::after {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to right,
      @background-border-dark,
      @background-border-light
    );
    height: 1px;
    content: '';
  }
}

input.noArrows::-webkit-outer-spin-button,
input.noArrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.noArrows[type='number'] {
  -moz-appearance: textfield;
}

div.light {
  border-radius: 50%;
  background-color: @background-border-light;
  width: 8px;
  height: 8px;
}

/* Animation
// ========================================================================= */

@easeInBack: cubic-bezier(0.51, -0.6, 0.735, 0.045);
@easeOutBack: cubic-bezier(0.175, 0.885, 0.305, 1.65);
@easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

/* Misc
// ========================================================================= */

@round: 8px;
@round-lg: 16px;
